* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 1200px;
}

.App {
  display: flex;
}

h1 {
  text-align: center;
  color: #dfb52a;
}

.white {
  background-image: "../src/assets/Solid_white.png";
  border: 1px solid black;
  cursor: pointer;
}

.cell-size {
  width: 50px;
  height: 50px;
  border: 1px solid black;
}

.modify-cell-size {
  width: 40px;
  height: 40px;
}

.modify-cell-size1 {
  width: 30px;
  height: 30px;
}

.red {
  background-image: "../src/assets/Solid_white.png";
  border: 1px solid black;
  cursor: pointer;
}

.turn-gold {
  background-image: url("../public/pot-of-gold-2.png");
  border: 3px solid #edcd22;
  background-size: 120%;
  background-position: center center;
}

.turn-boom {
  background-image: url("../public/boom-3307008.svg");
  border: 3px solid #e71b1b;
}

.counters {
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  font-size: 20px;
}

.countersWrapper {
  background-color: white;
}

.counterWrapper {
  height: auto;
  width: auto;
  padding: 0;
  vertical-align: bottom;
}

.counter {
  margin: 2px;
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
}

.modify-counter-font-size {
  font-size: 14px;
}

.matrixTable-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 820px;
  height: 100vh;
}

.input-container {
  margin-left: 20px;
}

.matrixTable-child {
  border: 10px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to right bottom, #f2e552, #bf930f);
  padding: 10px;
  animation: glowing 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}
.input-group {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inp {
  font-size: 19px;
  font-family: "Courier New", Courier, monospace;
  display: flex;
}

.select-input {
  margin: 10px 0;
  font-size: 24px;
  width: 300px;
  text-align: center;
  height: 40px;
  border-radius: 4px;
  background-color: #f1f1f1;
  overflow: hidden;
  font-family: "Courier New", Courier, monospace;
  outline: none;
}

.select-input:hover {
  border: #f2e552 5px solid;
  cursor: pointer;
}

.glow-on-hover {
  width: 300px;
  height: 40px;
  border: none;
  outline: none;
  color: black;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  font-size: 24px;
  font-family: "Courier New", Courier, monospace;
  display: flex;
  justify-content: center;
  align-items: center;
}

#play-btn-icon {
  display: flex;
  align-items: center;
}

.glow-on-hover:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  color: #000;
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #f2e552;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

/* Styles for the modal */
.overlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  border: 5px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to right bottom, #f2e552, #bf930f);
}

/* Additional styles for the modal content */
.popup h2 {
  margin-top: 0;
}

.popup p {
  margin-bottom: 20px;
}

#matrixTable td.flag {
  background-image: url("/public/flag.svg");
  background-repeat: no-repeat;
  border: orange 3px solid;
}

.tutorial-container {
  border: #dfb52a 5px solid;
  margin-top: 15px;
  box-sizing: border-box;
}

.tutorial-header {
  margin: 10px 0;
  text-align: center;
  font-size: 24px;
}

.tutorial-goal {
  width: 400px;
  display: flex;
  flex-direction: column;
  padding: 0px 10px 10px 10px;
  text-align: justify;
}

.tutorial-goal > h4 {
  text-align: center;
  line-height: 20px;
  margin-bottom: 10px;
}

.tutorial-goal > p {
  margin-left: 100px;
  line-height: 20px;
}

.left-click {
  display: flex;
  width: 100%;
  height: 10%;
  align-items: center;
  font-size: 18px;
  justify-content: center;
}

.left-click-image {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.gold-image {
  border-right: #000 solid 2px;
}

.right-click {
  margin-top: 10px;
  display: flex;
  align-items: center;
  font-size: 18px;
  justify-content: center;
}

.right-click-image {
  width: 50px;
  height: 50px;
  margin-left: 30px;
  margin-right: 45px;
}

.tutorial-content {
  width: 400px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  text-align: justify;
}

.win-or-lose {
  border: #edcd22 2px solid;
  font-size: 18px;
  padding: 5px;
}

.win-or-lose > h3 {
  text-align: center;
}

.tutorial-powered-by {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.tutorial-powered-by > p {
  font-weight: 500;
}

.techwiz-logo {
  width: 100px;
  margin-left: 5px;
}

.timer-container {
  position: absolute;
  top: 10px;
  right: 40px;
  margin-top: 50px;
  height: 50px;
  width: 180px;
  background-color: #f2e552;
  font-size: 42px;
  text-align: center;
  border-radius: 20px;
}

.time-taken {
  font-size: 28px;
  margin: 10px 0px;
  border-radius: 5px;
}

#win-time {
  margin-left: 5px;
}

#win-header {
  font-size: 36px;
}

.close-btn {
  width: 100%;
}

.lose-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.highlight {
  background-color: #f7f391;
}

.input-save-player {
  outline: invert;
  outline-color: #bf930f;
  width: 70%;
  height: 50px;
  font-size: 18px;
  padding: 10px;
  margin-bottom: 20px;
  text-align: center;
  overflow: hidden;
  box-sizing: border-box;
  border: 3px solid #f2e552;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.save-record-btn {
  margin-bottom: 30px;
  text-align: center;
  width: 40%;
  align-items: center;
  left: 130px;
}

.leader-board-container {
  border: #dfb52a 5px solid;
  width: 230px;
  height: 620px;
  margin-left: 5px;
  position: absolute;
  top: 150px;
  right: 20px;
}

.leader-board-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.leader-board-header {
  border-bottom: 2px solid #bf930f;
  width: 100%;
  text-align: center;
  padding: 10px;
}

.leader-board-player {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
}

.challenge-link-container {
  font-size: 18px;
  display: flex;
  height: 100px;
  flex-direction: column;
  align-items: center;
  align-content: space-between;
}

.challenge-link {
  font-size: 14px;
  margin: 10px;
}

.copy-link {
  height: 40px;
  width: 80px;
}

/* Highlight row */
.highlight-row {
  position: relative;

  background-color: rgba(255, 255, 0, 0.5); /* Adjust highlight color */
  top: 0;
  left: 0;

}

/* Highlight column */
.highlight-col {
  position: relative;

  background-color: rgba(255, 255, 0, 0.5); /* Adjust highlight color */
  top: 0;
  left: 0;

}
